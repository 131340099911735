define("discourse/plugins/waze-email-templates/discourse/models/admin-plugins-anonymous-email-log", ["exports", "admin/models/admin-user", "@ember/object", "discourse/lib/ajax", "discourse-common/lib/get-url"], function (_exports, _adminUser, _object, _ajax, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AnonymousEmailLog {
    constructor(attrs) {
      this.opened = attrs.opened;
      this.sent = attrs.sent;
      this.post_author = attrs.post_author;
      this.post_created_at = attrs.post_created_at;
      this.post_description = attrs.post_description;
      this.post_url = attrs.post_url;
    }
    get open_rate() {
      return `${Math.round((this.opened || 0) / this.sent * 100)}%`;
    }
    static #_ = (() => dt7948.n(this.prototype, "open_rate", [(0, _object.computed)("sent", "opened")]))();
    static create(attrs) {
      attrs = attrs || {};
      if (attrs.post_author) {
        attrs.post_author = _adminUser.default.create(attrs.post_author);
      }
      if (attrs.post_url) {
        attrs.post_url = (0, _getUrl.default)(attrs.post_url);
      }
      return new AnonymousEmailLog(attrs);
    }
    static async findAll(offset) {
      offset = offset || 0;
      try {
        const logs = await (0, _ajax.ajax)(`/admin/plugins/anonymous-email-log/summary.json?offset=${offset}`);
        return logs.map(log => AnonymousEmailLog.create(log));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }
  _exports.default = AnonymousEmailLog;
});