define("discourse/plugins/waze-email-templates/discourse/controllers/admin-plugins-anonymous-email-log", ["exports", "@ember/controller", "admin/models/email-log", "discourse/plugins/waze-email-templates/discourse/models/admin-plugins-anonymous-email-log", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _emailLog, _adminPluginsAnonymousEmailLog, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsAnonymousEmailLogController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    loadLogs(loadMore) {
      if (loadMore && this.loading || this.get("model.allLoaded")) {
        return;
      }
      this.set("loading", true);
      return _adminPluginsAnonymousEmailLog.default.findAll(loadMore ? this.get("model.length") : null).then(logs => {
        if (this.model && loadMore && logs.length < 50) {
          this.model.set("allLoaded", true);
        }
        if (this.model && loadMore) {
          this.model.addObjects(logs);
        } else {
          this.set("model", logs);
        }
      }).finally(() => this.set("loading", false));
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadLogs", [_object.action]))();
    loadMore() {
      this.loadLogs(_emailLog.default, true);
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = AdminPluginsAnonymousEmailLogController;
});